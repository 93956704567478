<template>
  <div class="align">
    <div id="loader" v-if="loadingDeals">
      <loader></loader>
    </div>

    <div v-else>
      <div v-if="!activeDealData('DealInfo') || activeDealData('DealInfo').StateDeal === 1">
        <div class="contentBlock">
          <div class="title">
            <h3 v-if="!!getFirstSecondName">{{ getFirstSecondName }},</h3>
            <h3 v-else>Уважаемый клиент,</h3>
            <h3>в данный момент у Вас нет активных займов</h3>
          </div>

        </div>
      </div>
      <div id="dealData" v-else-if="activeDealData('DealInfo').StateDeal === 0">
        <dealsSelect></dealsSelect>
        <div class="dealsMainContent" v-if="activeDealData('DealInfo').DlCode !== undefined">

          <!--      блок "Действующий заём"-->
          <div id="activeDemand" class="contentBlock">
            <div class="title">
              <h3>Действующий заём</h3>
            </div>
            <div class="body">
              <div class="bodyPoints">
                <div class="point">
                  <p v-if="isPdl">Сумма платежа</p>
                  <p v-else>Сумма рекомендованного платежа</p>
                  <p :inner-html.prop="activeDealData('DealInfo').CurPay | moneyFormat"></p>
                </div>
                <div class="point">
                  <p v-if="isPdl">Дата платежа</p>
                  <p v-else>Дата следуюшего платежа</p>
                  <p>{{ activeDealData('DealInfo').CurDate | timeFromXtoDateView }}</p>
                </div>

                <div class="point" v-if="activeDealData('DealInfo').CostBoxPDL > 0">
                  <!--          todo не хватает поля-->
                  <p>Страхование займа</p>
                  <p :inner-html.prop="activeDealData('DealInfo').CostBoxPDL | moneyFormat"></p>
                </div>
                <div class="point">
                  <!--            todo поле под вопросом - нужно ли такое-->
                  <p>Внесено</p>
                  <p :inner-html.prop="activeDealData('DealInfo').PaySumm | moneyFormat"></p>
                </div>
              </div>

              <div class="bodyLinks">
                <a href="https://www.vsk.ru/cms/assets/4843446e-6f06-438e-8cbc-bfa681b13cb8"
                  v-if="activeDealData('DealInfo').CostBoxPDL > 0">
                  <span>Условия страхования</span>
                </a>
                <router-link to="/PayList">
                  <span>История операций</span>
                </router-link>
                <router-link to="/PayGraphic">
                  <span>График платежей</span>
                </router-link>
              </div>

              <div class="bodyButton">
                <button v-on:click="redirectToPayment">внести платёж</button>
              </div>

            </div>



          </div>

          <div id="rightBlock" v-if="activeDealData('DealInfo').StateDeal === 0">

            <!--      блок с баннером-->
          <router-link v-if="!isPdl" to="/addRecomendation">
            <img src="@/assets/vector/greenBanner.svg" class="greenBanner">
          </router-link>
            <!--      блок лонгации-->
            <div id="longation" class="contentBlock" v-if="activeDealData('DealInfo').CanLong === 1 && isPdl">
              <div class="body centered">
                <p>Не успеваете внести платёж?</p>
                <p>Для вас доступна услуга<br>"Пролонгация займа"</p>
                <div>
                  <div class="point">
                    <p>Сумма платежа</p>
                    <p :inner-html.prop="activeDealData('DealInfo').SumForLong | moneyFormat"></p>
                  </div>
                  <div class="point">
                    <p>Дата платежа</p>
                    <p>{{ activeDealData('DealInfo').NewDateLong | timeFromXtoDateView }}</p>
                  </div>
                </div>
              </div>

              <div class="bodyButton">
                <button v-on:click="longationPage">Пролонгировать займ</button>
              </div>
              <div class="longationAgreement">
                <!--        todo сказать Косте, чтобы добавил на бек галочку по лонгации-->
                <fmCheckBox :name="'LONGATION'" :trueValue="'true'" :falseValue="'false'"></fmCheckBox>
                <a href="https://finmoll.ru/backend/content/documents/rules_prolongation_loan">Подробнее</a>
              </div>
            </div>
          </div>

          <!--      Блок "Общая информация по договору"-->

          <div id="allInfo" class="contentBlock">
            <div class="title">
              <h3>Общая информация по договору</h3>
            </div>
            <div class="body">
              <div class="info">
                <div class="point">
                  <p>Номер договора</p>
                  <p>{{ activeDealData('DealInfo').DlCode }}</p>
                </div>
                <div class="point">
                  <p>Дата заключения договора</p>
                  <p>{{ activeDealData('DealInfo').ValutationDate | timeFromXtoDateView }}</p>
                </div>
                <div class="point">
                  <!--          todo не хватает поля-->
                  <p>Дата последнего платежа</p>
                  <p>{{ activeDealData('DealInfo').PayLastDate | timeFromXtoDateView }}</p>
                </div>
                <template v-if="!isPdl">
                  <div class="point">
                    <p>Сумма договора</p>
                    <p :inner-html.prop="activeDealData('DealInfo').DealSum | moneyFormat"></p>
                  </div>
                  <div class="point">
                    <p>Еженедельный платёж</p>
                    <p :inner-html.prop="activeDealData('DealInfo').FixedPay | moneyFormat"></p>
                  </div>
                  <div class="point">
                    <!--          todo не хватает поля-->
                    <p>Осталось выплатить</p>
                    <p :inner-html.prop="activeDealData('DealInfo').TotalDebt | moneyFormat"></p>
                  </div>
                </template>
              </div>
              <div class="infoBlockLinks">
                <a v-if="!!activeDealData('DealInfo').anketa_url" :href="activeDealData('DealInfo').anketa_url" target="_blank">Заявление-анкета</a>
                <a v-if="!!activeDealData('DealInfo').iu_url" :href="activeDealData('DealInfo').iu_url" target="_blank">Индивидуальные условия</a>
                <a v-if="!!activeDealData('DealInfo').pdn_url" :href="activeDealData('DealInfo').pdn_url" target="_blank">Уведомление о превышении значения ПДН</a>
                <a v-if="!!activeDealData('DealInfo').boxNotifications" :href="activeDealData('DealInfo').boxNotifications" target="_blank">Уведомление о предоставлении услуг третьих лиц</a>
                <a v-if="activeDealData('DealInfo').isAbsolut === 1" href="/pdf/kid.pdf" target="_blank">Ключевой информационный документ ООО «Абсолют Страхование»</a>
                <a href="https://finmoll.ru/backend/content/documents/microloan_terms_conditions" target="_blank">Общие условия займа</a>
              </div>
              <div class="support">
                <div class="title">
                  <h3>Поддержка</h3>
                </div>
                <div class="body">
                  <div class="point help">
                    <a href="tel:+78006009990">8 (800) 600 99 90</a>
                    <div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17.707 13.707l2.648 2.648a.912.912 0 010 1.29 5.471 5.471 0 01-7.151.508l-1.575-1.182a22.996 22.996 0 01-4.6-4.6l-1.182-1.575a5.471 5.471 0 01.508-7.151.912.912 0 011.29 0l2.648 2.648a1 1 0 010 1.414L9.272 8.728a.533.533 0 00-.1.616 12.262 12.262 0 005.484 5.484.533.533 0 00.616-.1l1.02-1.02a1 1 0 011.415 0z" stroke="#000"/></svg>
                      <p>Звонок бесплатный по РФ</p>
                    </div>
                  </div>
                  <div class="point help">
                    <a href="mailto:info@msk.finmoll.com" target="_blank">info@msk.finmoll.com</a>
                    <div>
                      <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="1" y="1" width="16" height="10.706" rx="2" stroke="#000"/><path d="M1 3.677l7.185 3.205a2 2 0 001.63 0L17 3.677" stroke="#000"/></svg>
                      <p>Без выходных</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>

        </div>
      </div>
      <div v-else>
        <div class="contentBlock">
          <div class="title">
            <h3>{{ demandStatusToTxt(activeDealData('DealInfo').StateDeal) }}</h3>
          </div>
        </div>
      </div>
      <uiProductAmountSelector />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'contractDetails',
  data() {
    return {};
  },
  components: {
    loader: () => import('@/components/other/loader'),
    qr: () => import('@/components/qr/qr'),
    fmCheckBox: () => import('@/components/Form/element/fmCheckBox.vue'),
    dealsSelect: () => import('@/components/nav/dealsSelect'),
    uiProductAmountSelector: () => import('@/views/repeatLoan/uiProductAmountSelector')
  },
  methods: {
    longationPage() {
      this.$router.push('/payment/longation');
    },
    redirectToPayment() {
      this.$router.push('/payment/current');
    },
    demandStatusToTxt(status) {
      let out = '';
      switch (status) {
        case 0:
          out = 'Договор активный';
          break;
        case 1:
          out = 'Договор успешно закрыт';
          break;
        case 2:
          out = 'Договор на стадии оформления';
          break;
        default:
          out = 'Нет статуса';
      }
      return out;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // console.log(this.activeDealData('PayList'))
  },
  computed: {
    ...mapState({
      loadingDeals: (state) => state.contentData.deals.isLoading,
      payGraphic: (state) => state.contentData.deals.data
    }),
    ...mapGetters(['activeDealData',]),
    ...mapGetters('repeatLoan', ['getFirstSecondName']),
    isPdl() {
      return this.activeDealData('DealInfo').Is_pdl === 1
    }
  }
};
</script>

<style scoped>

#dealData  { display: flex; flex-direction: column;  }
#mainFirstBlock {  }
#rightBlock {  }
#mainFirstBlock .info .point { font-family: 'Roboto' }
#activeDemand .bodyPoints { display: flex; flex-wrap: wrap; justify-content: space-between; }
#activeDemand .bodyLinks { display: flex; justify-content: space-between; margin-bottom: 7.35vw; font-family: 'Ubuntu'; }
#activeDemand .bodyLinks>a { font-size: 2.2vw }
#activeDemand .bodyButton { display: flex; justify-content: center; padding-bottom: 7.35vw }
#allInfo>.body { display: flex; flex-direction: column }
#allInfo .info { display: flex; flex-direction: row; margin-bottom: 5vw; flex-wrap: wrap; }
#allInfo h3 { font-size: 4.9vw }
#allInfo .info>.point>p:first-child { font-size: 2.65vw; color: #828282 }
#allInfo .info>.point>p { line-height: inherit }
#allInfo .infoBlockLinks { display: flex;flex-wrap: wrap }
#allInfo .infoBlockLinks a { margin: 0 5.88vw 3.24vw 0; font-family: 'Ubuntu'; font-size: 2.65vw }
#allInfo .support { width: 100% }
#allInfo .support .body { display: flex }
#allInfo .support .point { font-family: 'Roboto'; margin: 0 3.88vw 2vw 0; width: auto }
#allInfo .support .point>a { font-size: 3.24vw;  }
#allInfo .support .point p { font-size: 2.65vw; color: #828282; margin-bottom: 0 }
#allInfo .support .point svg { width: 7.06vw; height: 7.06vw; margin-right: 0.5vw  }
#allInfo .body .point>p:first-child { margin-bottom: 0 }
#allInfo .body .point { display: flex; flex-direction: column; justify-content: space-between; width: 50%;}
#rightBlock .greenBanner { margin-bottom: 17px; width: 100% }
#rightBlock .centered { display: flex; flex-direction: column; align-items: center; margin-bottom: 4.41vw; font-family: 'Lato'; text-align: center }
#rightBlock .centered>p { font-weight: bold }
#rightBlock .centered>div { display: flex; justify-content: space-around; width: 100%}
#rightBlock .centered>div>.point{ display: flex; flex-direction: column; align-items: center }
#longation .bodyButton { display: flex; justify-content: center; margin-bottom: 7.65vw; }
#rightBlock .longationAgreement { display: flex; padding-bottom: 8.82vw; justify-content: center; align-items: center  }
#rightBlock .longationAgreement>a { padding-left: 0.59vw; font-family: 'Roboto'; font-size: 2.06vw }
@media screen and (min-width: 760px){
  .align { width: 100% }
}
@media screen and (min-width: 1200px){
  /** { border: 1px solid red }*/
  .align { width: 1085px }
  #dealData  { flex-direction: column }
  #dealData>.dealsMainContent { display: grid; grid-template-areas: "activeDemand activeDemand rightBlock" "allInfo allInfo rightBlock"; column-gap: 50px; row-gap: 20px; }
  #activeDemand { grid-area: activeDemand; }
  #rightBlock { grid-area: rightBlock; }
  #allInfo { grid-area: allInfo; }
  #mainFirstBlock { width: 655px  }
  #rightBlock { width: 396px }
  #activeDemand .body { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between }
  #activeDemand .bodyPoints { flex-direction: row; margin: 0 0 16px 0; width: 62% }
  #activeDemand .bodyPoints p { margin-bottom: 5px; line-height: normal; }
  #activeDemand .halfBody:last-child { display: flex; flex-direction: column; align-items: flex-end; }
  #activeDemand .bodyLinks { margin-bottom: 0; font-family: 'Ubuntu'; padding-bottom: 24px; justify-content: flex-start; order: 3  }
  #activeDemand .bodyLinks>a { font-size: 17px; margin-right: 29px }
  #activeDemand .bodyButton { padding-bottom: 0; align-items: center; padding-right: 21px }
  #allInfo h3 { font-size: 17px }
  #allInfo .info .point {  }
  #allInfo .info { margin-bottom: 11px; border-bottom: 1px solid #DFE0E1; }
  #allInfo .info>.point { flex-direction: column; width: auto; margin-right: 25px; width: 29%; }
  #allInfo .info>.point>p { font-size: 14px; line-height: inherit; }
  #allInfo .info>.point>p:first-child { font-size: 14px; margin-bottom: 5px;  }
  #allInfo .infoBlockLinks { align-items: flex-start;flex-wrap: wrap}
  #allInfo .infoBlockLinks>a { margin: 0 20px 11px 0; font-size: 17px }
  #allInfo .support { display: flex; flex-direction: column; }
  #allInfo .support .help { width: auto }
  #allInfo .support .help>div { display: flex; align-items: center; }
  #allInfo .support>div:last-child {  }
  #allInfo .support .point {  }
  #allInfo .support .point>a { font-size: 17px;  }
  #allInfo .support .point p { font-size: 14px; color: #828282 }
  #allInfo .support .point svg { width: 24px; height: 24px  }
  #rightBlock .greenBanner { margin-bottom: 17px; }
  #longation .centered>p:first-child{ margin-top: 22px }
  #longation .centered>p{ font-size: 17px; margin-bottom: 12px }
  #longation .centered { margin-bottom: 20px }
  #longation .centered>div{ margin-bottom: 0 }
  #longation .centered>div>.point{ }
  #longation .bodyButton { margin-bottom: 31px; }
  #longation .longationAgreement { padding-bottom: 33px  }
  #longation .longationAgreement>a { padding-left: 2px; font-size: 10px }



}
</style>
